import {
  CCol,
  CFormInput,
  CModalBody,
  CModalFooter,
  CRow,
} from '@coreui/react-pro'
import Layout from './Layout'
import SModal from '@/components/shared/Modal/SModal'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { IGame } from '@/shared/model/game.model'
import {
  ToastErrorBottom,
  ToastSuccessBottom,
} from '@/components/shared/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import KyQuay from './KyQuay'
import InfoIcon from '@/components/shared/icons/web/InfoIcon'
import { NavLink } from 'react-router-dom'

import img1 from '../../../assets/img/11.png'
import img2 from '../../../assets/img/22.jpg'
import img3 from '../../../assets/img/33.jpg'
import img4 from '../../../assets/img/44.jpg'
import img5 from '../../../assets/img/55.jpg'
import img6 from '../../../assets/img/66.png'
import img7 from '../../../assets/img/12.png'
import gif1 from '../../../assets/img/gif1.gif'

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [modal, setModal] = useState(false)

  const [tienDat, setTienDat] = useState<number>(100)
  const [biDat, setBiDat] = useState<number>(0)
  const [ky, setKy] = useState<IGame | null>(null)
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const [load, setLoad] = useState(false)
  const { user } = useSelector((state: RootState) => state.authentication)

  const subTienDatHandle = () => {
    if (tienDat > 10000) {
      setTienDat(tienDat - 10000)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current')
      setKy(response.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get('/draws/get-result-ket-qua-ky-truoc')
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKy()
    getKetQuaKyTruoc()
    dispatch(getProfile())
  }, [load])

  const addTienDatHandle = () => {
    setTienDat(tienDat + 10000)
  }

  const money = [100, 500, 1000, 5000, 10000, 30000, 50000, 100000]

  const onChangeTienDatHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (Number.isNaN(Number(value))) return
    setTienDat(Number(value))
  }

  const closeModal = () => {
    setModal(false)
    setBiDat(0)
  }

  const openModal = (biDat: number) => {
    setModal(true)
    setBiDat(biDat)
  }

  const submitHanle = async () => {
    if (disableDatCuoc) {
      ToastErrorBottom('Đặt lệnh thất bại')
      setModal(false)
      setBiDat(0)
      return
    }

    if (user?.money && user?.money < tienDat) {
      ToastErrorBottom('Đặt lệnh thất bại')
      setModal(false)
      setBiDat(0)
      return
    }

    try {
      const response = await axios.post('/history', {
        KyId: ky?.id,
        voted: biDat,
        money: tienDat,
      })
      setModal(false)
      setBiDat(0)
      setTienDat(100)
      ToastSuccessBottom('Đặt lệnh thành công')
      dispatch(getProfile())
    } catch (error) {
      setModal(false)
      setBiDat(0)
      // ToastErrorBottom('đặt lệnh thất bại')
      dispatch(getProfile())
      console.error('Error:', error)
    }
  }

  const [box1, setBox1] = useState(2)
  const [box2, setBox2] = useState(1)

  return (
    <Layout>
      <div>
        <div className="item-history d-flex justify-content-between align-items-center">
          <div style={{ fontSize: 16 }} className="fw-bold">
            <img className="logo-top" src={gif1} width={100} />
          </div>
          <div className="fw-bold">
            Mã sản phẩm hiện tại {(ky?.id ?? 0) - 1}
          </div>
          <div>
            <NavLink to="/info">
              <InfoIcon
                color="#fff"
                fill="#113b49"
                style={{ borderRadius: '50%', background: '#fff' }}
              />
            </NavLink>
          </div>
        </div>

        <div className="box-result d-flex justify-content-center align-items-center">
          <div className="text-center">
            <div className="list-ball d-flex justify-content-between gap-2">
              <div className="ball">
                <span>{ketQuaKyTruoc?.ball1 || 0}</span>
              </div>
              <div className="ball">
                <span>{ketQuaKyTruoc?.ball2 || 0}</span>
              </div>
              <div className="ball">
                <span>{ketQuaKyTruoc?.ball3 || 0}</span>
              </div>
              <div className="ball">
                <span>{ketQuaKyTruoc?.ball4 || 0}</span>
              </div>
              <div className="ball">
                <span>{ketQuaKyTruoc?.ball5 || 0}</span>
              </div>
            </div>
          </div>
        </div>

        <KyQuay
          load={load}
          ky={ky}
          setDisableDatCuoc={setDisableDatCuoc}
          setLoad={setLoad}
        />

        <div
          style={{ backgroundColor: '#113b49', height: 67 }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className="fw-bold d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: 'rgb(255, 99, 44)',
              color: '#fff',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 3,
              borderColor: '#fff',
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            Bảng phân phối công việc
          </div>
        </div>

        <div className="d-flex justify-content-between px-3 py-2 box-1">
          <div
            onClick={() => setBox1(1)}
            className={box1 === 1 ? 'active' : ''}
          >
            THỜI TRANG
          </div>
          <div
            onClick={() => setBox1(2)}
            className={box1 === 2 ? 'active' : ''}
          >
            SẢN PHẨM
          </div>
          <div
            onClick={() => setBox1(3)}
            className={box1 === 3 ? 'active' : ''}
          >
            KHÁCH HÀNG
          </div>
        </div>

        <div className="d-flex justify-content-between px-3 py-2 box-2">
          <div
            onClick={() => setBox2(1)}
            className={box2 === 1 ? 'active' : ''}
          >
            Quần áo
          </div>
          <div
            onClick={() => setBox2(2)}
            className={box2 === 2 ? 'active' : ''}
          >
            Túi xách
          </div>
          <div
            onClick={() => setBox2(3)}
            className={box2 === 3 ? 'active' : ''}
          >
            Giày dép
          </div>
          <div
            onClick={() => setBox2(4)}
            className={box2 === 4 ? 'active' : ''}
          >
            Phụ kiện
          </div>
          <div
            onClick={() => setBox2(5)}
            className={box2 === 5 ? 'active' : ''}
          >
            Nước hoa
          </div>
        </div>

        <div className="box-datcuoc mt-3">
          <div>
            <div className="d-flex gap-2">
              <button
                style={{ background: 'rgb(154, 195, 223)', height: 55 }}
                className={
                  biDat === 1
                    ? 'active justify-content-center'
                    : 'justify-content-center'
                }
                onClick={() => openModal(1)}
              >
                <span
                  className="fw-bold"
                  style={{ marginTop: 5, fontSize: 18 }}
                >
                  NHẬP
                </span>
              </button>
              <button
                style={{ background: '#83ba47', height: 55 }}
                className={
                  biDat === 2
                    ? 'active justify-content-center'
                    : 'justify-content-center'
                }
                onClick={() => openModal(2)}
              >
                <span
                  className="fw-bold"
                  style={{ marginTop: 5, fontSize: 18 }}
                >
                  XUẤT
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="box-image-1 p-3">
          <CRow>
            <CCol xs={4}>
              <div className="item">
                <img src={img1} />
                <h6 className="fw-bold mt-2">Quần áo</h6>
              </div>
            </CCol>

            <CCol xs={4}>
              <div className="item">
                <img src={img2} />
                <h6 className="fw-bold mt-2">Túi xách</h6>
              </div>
            </CCol>

            <CCol xs={4}>
              <div className="item">
                <img src={img3} />
                <h6 className="fw-bold mt-2">Giày dép</h6>
              </div>
            </CCol>

            <CCol xs={4}>
              <div className="item">
                <img src={img4} />
                <h6 className="fw-bold mt-2">Phụ kiện</h6>
              </div>
            </CCol>

            <CCol xs={4}>
              <div className="item">
                <img src={img5} />
                <h6 className="fw-bold mt-2">Thắt lưng</h6>
              </div>
            </CCol>

            <CCol xs={4}>
              <div className="item">
                <img src={img6} />
                <h6 className="fw-bold mt-2">Nước hoa</h6>
              </div>
            </CCol>
          </CRow>
        </div>

        <div className="p-3">
          <img src={img7} className="w-100" />
        </div>

        <div className="notegame">
          <p className="mb-0">
            TRI ÂN KHÁCH HÀNG NHÂN DỊP KỶ NIỆM 10 NĂM THÀNH LẬP
            DOANH NGHIỆP CÔNG NGHỆ AUTOSALE CHALLENGE . PHẦN QUÀ LÊN ĐẾN
            1.000.000 USD !
          </p>
        </div>
      </div>

      <SModal
        alignment="center"
        visible={modal}
        backdrop="static"
        className="custom-modal custom-modal-datcuoc"
      >
        {/* <CModalHeader>dsadas</CModalHeader> */}
        <CModalBody>
          <div>
            <div className="text-center mb-3">Số lượng</div>

            <div className="form-input d-flex gap-3 justify-content-center align-items-center">
              <div className="sub" onClick={subTienDatHandle}>
                -
              </div>
              <CFormInput
                onChange={(e) => onChangeTienDatHandle(e)}
                className="text-center fw-normal"
                type="text"
                value={tienDat}
              />
              <div className="add" onClick={addTienDatHandle}>
                +
              </div>
            </div>

            <CRow className="box-list-fill mt-4">
              {money.map((item, index) => {
                return (
                  <CCol xs={3} key={index}>
                    <div
                      className={
                        'fill mb-2' + (item === tienDat ? ' active' : '')
                      }
                      onClick={() => setTienDat(item)}
                    >
                      <span>
                        {new Intl.NumberFormat('vi-VN').format(item || 0)}
                      </span>
                    </div>
                  </CCol>
                )
              })}
            </CRow>
          </div>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-around">
          <div
            className="cancel fw-bold"
            onClick={closeModal}
            style={{
              background: 'rgb(204, 204, 204)',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: 25,
            }}
          >
            Hủy bỏ
          </div>
          <div
            className="submit fw-bold"
            onClick={submitHanle}
            style={{
              background: '#113b49',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: 25,
              color: "#fff"
            }}
          >
            Xác nhận
          </div>
        </CModalFooter>
      </SModal>
    </Layout>
  )
}

export default Home
