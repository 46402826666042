import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import AddBank from './AddBank';
import Withdraw from './Withdraw';
import HistoryOrder from './HistoryOrder';
import HistoryWithdraw from './HistoryWithdraw';
import HistoryDeposit from './HistoryDeposit';
import ChangePassword from './ChangePassword';
import Message from './Message';
import Home from './Home';
import Account from './Account';
import History from './History';
import Info from './Info';
import ChatIfame from './ChatIframe';

// const Home = React.lazy(() => import('./Home'));
// const Account = React.lazy(() => import('./Account'));
// const History = React.lazy(() => import('./History'));

const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/account',
    element: <Account />,
  },

  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/add-bank',
    element: <AddBank />,
  },
  {
    path: '/withdraw',
    element: <Withdraw />,
  },
  {
    path: '/cskh',
    element: <Withdraw />,
  },
  {
    path: '/history-order',
    element: <HistoryOrder />,
  },
  {
    path: '/history-withdraw',
    element: <HistoryWithdraw />,
  },
  {
    path: '/history-deposit',
    element: <HistoryDeposit />,
  },

  {
    path: '/change-password',
    element: <ChangePassword />,
  },

  {
    path: '/message',
    element: <Message />,
  },

  {
    path: '/info',
    element: <Info />,
  },
  {
    path: '/chat',
    element: <ChatIfame />,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const WebsManagementRoutes = () => useRoutes(WebManagementLayout);
export default WebsManagementRoutes;
