import { IHistory } from '@/shared/model/history.model'
import Layout from './Layout'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { IGame } from '@/shared/model/game.model'

const History = () => {
  const [games, setGames] = useState<IGame[] | []>([])
  const getKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?status=closed&sortOrder=DESC&sortBy=id&limit=20',
      )
      setGames(response.data.data)
    } catch (error) {
      setGames([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQua()
  }, [])

  return (
    <Layout>
      <div className="box-history">
        <div className="box-header">
          <div className="text-white fw-bold m-0">Xu hướng kỷ lục</div>
        </div>

        <div
          style={{ backgroundColor: 'rgb(104, 133, 141)', height: 67 }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className="fw-bold d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: '#ff632c',
              color: '#fff',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 3,
              borderColor: '#fff',
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            CHALLENGE
          </div>
        </div>

        <div className="box-content mt-2 px-2">
          <CTable
            bordered
            borderColor="#1989fa"
            className="custom-table-history"
            align="middle"
          >
            <CTableHead>
              <CTableRow style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                <CTableHeaderCell
                  style={{
                    backgroundColor: 'rgb(51, 51, 51)',
                    color: '#fff',
                    fontSize: 14,
                    borderRightWidth: 1,
                    borderRightColor: '#fff',
                    borderRightStyle: 'solid',
                  }}
                  scope="col"
                  className="w-35 text-center fw-normal py-3"
                >
                  Số đơn
                </CTableHeaderCell>
                <CTableHeaderCell
                  style={{
                    backgroundColor: 'rgb(51, 51, 51)',
                    color: '#fff',
                    fontSize: 14,
                  }}
                  scope="col"
                  className="w-65 text-center fw-normal py-3"
                >
                  Kết quả
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {Array.isArray(games)
                ? games.map((item, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell
                        className="w-35 text-center"
                        style={{ fontSize: 14 }}
                      >
                        {item.id}
                      </CTableDataCell>
                      <CTableDataCell className="w-65 text-center">
                        <div className="d-flex justify-content-center gap-2 list-ball">
                          <div className="ball">
                            <span>{item?.ball1}</span>
                          </div>
                          <div className="ball">
                            <span>{item?.ball2}</span>
                          </div>
                          <div className="ball">
                            <span>{item?.ball3}</span>
                          </div>
                          <div className="ball">
                            <span>{item?.ball4}</span>
                          </div>
                          <div className="ball">
                            <span>{item?.ball5}</span>
                          </div>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  ))
                : ''}
            </CTableBody>
          </CTable>
        </div>
      </div>
    </Layout>
  )
}

export default History
